import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {environment} from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.appVersion,
    pathMatch: 'full'
  },
  {
    path: environment.appVersion,
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'psi',
    loadChildren: () => import('./pages/gis/psi/psi.module').then( m => m.PsiPageModule)
  },
  {
    path: 'iscan',
    loadChildren: () => import('./pages/iscan/iscan.module').then( m => m.IscanPageModule)
  },
  {
    path: 'iflash',
    loadChildren: () => import('./pages/iflash/iflash.module').then( m => m.IflashPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
