// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
declare var require: any

export const environment = {
  production: false,
  apiKey: '',
  apiUrl: 'https://api-gis.globalinspectionspecialist.com',  //https://api-gis.globalinspectionspecialists.com
  ftp: '',
  appVersion: require('package.json').version + '-dev',
};
//https://localhost:7132/
// https://saapi.theglobalinspection.com
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
